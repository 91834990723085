export const DATA_TUTORIAL_COURSE = [
  {
    id: 0,
    title: 'Olá, vestibulando(a). Tudo bem com você?',
    text: '<p>Antes de começar a praticar, gostaríamos de explicar como funcionam os nossos cursos.</p>',
  },
  {
    id: 1,
    title: 'Tipos de cursos',
    text: '<p>Nossos cursos estão divididos em: <strong>extensivos, intensivos e revisões</strong>, que se adequam a sua rotina e tempo para estudar até o dia da prova.</p>',
  },
  {
    id: 2,
    title: 'Configuração dos cursos',
    text: '<p><strong>Língua estrangeira</strong></p><p>Ao entrar no curso, para que possa iniciá-lo, é necessário que defina qual língua estrangeira deseja estudar, podem ser uma das opções ou todas.</p><p><strong>Turmas</strong></p><p>As turmas funcionam com um filtro organizador da distribuição de todo o conteúdo que terá para estudar, baseado no período e tipo de curso o qual adquiriu.</p>',
    imgs: [
      {
        src: 'language.svg',
        alt: 'A imagem mostra um modal para definir uma linguagem estrangeira que o aluno pretende estudar, localizado ao clicar em um dos cursos da plataforma que será iniciado os estudos.',
      },
      {
        src: 'team.svg',
        alt: 'A imagem mostra o campo de seleção de turmas localizado dentro do curso, onde o aluno pode realizar a troca de turma, o que resultará na troca dos conteúdos a serem estudados baseado no período e tipo de curso que adquiriu.',
      },
    ],
  },
  {
    id: 3,
    title: 'Conteúdo',
    text: '<p>Cada curso possui um Guia de orientação exclusivo para cada Turma, nele você terá planejamento, cronograma com a carga horária de aulas, prática com exercícios de 35h semanais e ementa de estudos específicos preparados para estudar até a data da prova.</p><p>São milhares de videoaulas e materiais em PDF organizados para seu estudo. </p>',
    imgs: [
      {
        src: 'team.svg',
        alt: 'A imagem mostra o botão de baixar o cronograma do curso localizado dentro do curso.',
      },
    ],
  },
  {
    id: 4,
    title: 'Conheceu nossos Cursos?',
    text: '<p>Agora que já sabe tudo o que precisa saber dos nossos Cursos. Vamos dar início aos seus estudos?</p>',
  },
];

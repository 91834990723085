
















































import { Component, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerTutorial from '@/components/ContainerTutorial/ContainerTutorial.vue';
import Footer from '@/components/Footer/Footer.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { DATA_TUTORIAL_REDACTION } from './ConstantTutorialsRedaction';

import { OnboardingClass } from '@/mixins/Onboarding';
import { NavigationTutorials } from '@/mixins/NavigationTutorials';

@Component({
  components: {
    PageHeader,
    ContainerTutorial,
    Footer,
  },
})
export default class TutorialRedaction extends Mixins(OnboardingClass, NavigationTutorials) {
  private isLoading = false;

  created() {
    this.saveDataTutorials(DATA_TUTORIAL_REDACTION);
  }

  mounted() {
    this.setBreadCrumbs();
  }

  async updateOnboardingView() {
    try {
      this.isLoading = true;
      await this.updateOnboarding(LIST_ONBOARDING_ID.REDACTION);
      await this.timerUpdateProfile(this.goToRedactionPage);
    } catch (error) {
      console.error(error);

    } finally {
      this.isLoading = false;
    }
  }

  goToRedactionPage() {
    this.$router.push({
      name: 'Redaction',
    });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Redação ', to: '/redacao' },
      { title: 'Tutorial ', to: null },
    ]);
  }
}




















































import { Component, Mixins, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerTutorial from '@/components/ContainerTutorial/ContainerTutorial.vue';
import Footer from '@/components/Footer/Footer.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { DATA_TUTORIAL_COURSE } from './ConstantTutorialsCourse';

import { DataTutorial, Course } from './IDataTutorial';

import { OnboardingClass } from '@/mixins/Onboarding';
import AccessCourse from '@/mixins/AccessCourses';
import generalBooks from '@/mixins/generalBooks';
import { NavigationTutorials } from '@/mixins/NavigationTutorials';

@Component({
  components: {
    PageHeader,
    ContainerTutorial,
    Footer,
  },
})
export default class TutorialCourse extends Mixins(OnboardingClass, AccessCourse, generalBooks, NavigationTutorials) {
  private isLoading = false;

  created() {
    this.saveDataTutorials(DATA_TUTORIAL_COURSE);
    this.checkRouteCourse();
    this.setBreadCrumbs();
  }

  get paramIdCourse() {
    return this.$route.params?.id;
  }

  get courseStore() {
    const listCourses = this.$store.getters.courses;
    const findCourse = listCourses.find((course: Course) => course.idCourses === Number(this.paramIdCourse));
    return findCourse;
  }

  get courseName() {
    return this.courseStore.title;
  }

  @Watch('$route')
  checkRouteCourse() {
    if (this.paramIdCourse) {
      const newListTutorials = this.setLastTutorialIndex();
      this.saveDataTutorials(newListTutorials);
    } else {
      this.saveDataTutorials(DATA_TUTORIAL_COURSE);
    }
  }

  setLastTutorialIndex() {
    return DATA_TUTORIAL_COURSE.map((tutorial: DataTutorial) => {
      if (tutorial.id === this.lastPage) {
        return {
          ...tutorial,
          text: `<p>Agora que já sabe tudo o que precisa saber dos nossos Cursos. Vamos dar início ao seu curso de ${this.courseName}?</p>`,
        };
      }
      return tutorial;
    });
  }

  setRedirectRoute() {
    if (this.paramIdCourse) this.verifyCourseAccess();
    else {
      this.$router.push({
        name: 'CoursesList',
      });
    }
  }

  verifyCourseAccess() {
    const firstAccess = this.checkCourseAccess(Number(this.paramIdCourse));
    const coursePermission = this.isDisableCourse(Number(this.paramIdCourse));

    if (firstAccess && coursePermission) this.openModalStartCourse();
    else {
      this.isLoading = true;
      this.goToCourse(this.courseStore);
    }
  }

  async updateOnboardingCourse() {
    try {
      await this.updateOnboarding(LIST_ONBOARDING_ID.COURSE);
      if (!this.onboardViewed(LIST_ONBOARDING_ID.COURSE)) await this.timerUpdateProfile(this.setRedirectRoute);
      else this.setRedirectRoute();
    } catch (error) {
      console.error(error);
    }
  }

  openModalStartCourse() {
    this.$Bubble.emit('modalCourse', { idCourse: this.paramIdCourse });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Cursos ', to: '/cursos' },
      { title: 'Tutorial ', to: null },
    ]);
  }
}
